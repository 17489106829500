<template>
  <div id="Month">
    <!-- <div>
      <div class="btn-group">
        <router-link to=""
          ><font-awesome-icon icon="list-alt" class="icon"
        /></router-link>
        <router-link to=""
          ><font-awesome-icon icon="table" class="icon"
        /></router-link>
      </div>

      

      <h1 class="brand-color-dark">Monatsübersicht</h1>
      <h4 class="brand-color-dark">Januar 2021</h4>
    </div> -->

    <div class="lg:sticky lg:top-16 relative top-0 border-b-4 border-blue-700 rounded-md grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2 justify-between items-center px-3 py-3 bg-gray-800 my-4">
      <div><h1 class="brand-color-dark text-center sm:text-left">Monatsübersicht</h1></div>
      <div class="flex items-center justify-between gap-2"><button @click="calendarLeft"><font-awesome-icon icon="chevron-left" class="icon" /></button><button  @click="calendarToday" class="text-lg">{{getMonthLabel(shiftViewerCalendar.month)}} {{shiftViewerCalendar.year}}</button><button @click="calendarRight"><font-awesome-icon icon="chevron-right" class="icon" /></button></div>
      <div class="flex flex-col items-end text-right">
        <!-- <div class="flex items-center justify-center sm:justify-end gap-2"><button><font-awesome-icon icon="list-alt" class="icon" /></button><button><font-awesome-icon icon="table" class="icon" /></button></div> -->
        <div class="p-2">
          <label class="checkbox-container"
            > Vergangene Dienste<br>ausblenden
            <input type="checkbox" v-model="hidePastShifts" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="inline-block lg:hidden border-2 border-blue-700 fixed bottom-3 rounded-md px-3 py-3 bg-gray-800">
      <div class="flex items-center justify-between gap-2"><button @click="calendarLeft"><font-awesome-icon icon="chevron-left" class="icon" /></button><button  @click="calendarToday" class="text-lg">{{getMonthLabel(shiftViewerCalendar.month)}} {{shiftViewerCalendar.year}}</button><button @click="calendarRight"><font-awesome-icon icon="chevron-right" class="icon" /></button></div>
    </div>

    

    <!-- <table>
      <thead>
        <tr>
          <th>Datum</th>
          <th>Dienst</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>01.05.21</td>
          <td>16:55 Uhr bis 01:15 Uhr</td>
        </tr>
      </tbody>
    </table> -->

    <!-- The Modal -->
    <ModalShiftToExchange v-show="isModalVisible" @close="closeModal" :shift="exchangeData" />

    <ModalShiftRating v-show="isModalRatingVisible" @close="closeModal" :shift="modalDataRating" />

    <ModalShiftDiary v-show="isModalDiaryVisible" @close="closeModal" :shift="modalDataDiary" />


    <!-- <table class="table-fixed border-collapse border border-blue-800">
      <thead class="bg-black">
        <tr>
          <th class=" border border-blue-600">Mo</th>
          <th class=" border border-blue-600">Di</th>
          <th class=" border border-blue-600">Mi</th>
          <th class=" border border-blue-600">Do</th>
          <th class=" border border-blue-600">Fr</th>
          <th class=" border border-blue-600">Sa</th>
          <th class=" border border-blue-600">So</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="border border-blue-600">1.</td>
          <td class=" border border-blue-600">2.</td>
          <td class=" border border-blue-600">3.</td>
          <td class="border border-blue-600">4.</td>
          <td class=" border border-blue-600">5.</td>
          <td class=" border border-blue-600">6.</td>
          <td class=" border border-blue-600">7.</td>
        </tr>
        <tr class="">
          <td class=" border border-blue-600">16:22 Uhr - 02:53 Uhr</td>
          <td class=" border border-blue-600">16:22 Uhr - 02:53 Uhr</td>
          <td class=" border border-blue-600">16:22 Uhr - 02:53 Uhr</td>
          <td class=" border border-blue-600">16:22 Uhr - 02:53 Uhr</td>
          <td class=" border border-blue-600">16:22 Uhr - 02:53 Uhr</td>
          <td class=" border border-blue-600">16:22 Uhr - 02:53 Uhr</td>
          <td class=" border border-blue-600">16:22 Uhr - 02:53 Uhr</td>
        </tr>
      </tbody>
    </table> -->

    <div class="content-card-container flex-align-items-start items-start grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-3">
      <!-- <a href="/new" class="hover:border-blue-500 hover:border-solid hover:bg-white hover:text-blue-500 group w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-300 text-sm leading-6 text-gray-900 font-medium py-3">
        <svg class="group-hover:text-blue-500 mb-1 text-gray-400" width="20" height="20" fill="currentColor" aria-hidden="true">
          <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
        </svg>
        New project
      </a> -->
      <div v-show="shifts.length != 0 && hidePastShifts" class="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4">
        <div class="text-left flex items-center gap-4 p-4 bg-card-content">
          <div><p class="brand-color-dark text-4xl"><font-awesome-icon icon="hand-point-right" class="icon" /></p></div>
          <div>
            <p><span class="font-medium">Vergangene Dienste sind zurzeit ausgeblendet.</span> Möchtest Du sie <button @click="hidePastShifts=false" class="link">einblenden</button>?</p>
          </div>
        </div>
      </div>

      <ShiftBox
        determination
        :data="shift"
        v-for="shift in shifts"
        :key="shift.id"
        :hidePastShifts="hidePastShifts"
        @openExchangeModal="showModal"
        @openModalRating="showModalRating"
        @openModalDiary="showModalDiary"
      ></ShiftBox>
    </div>
    <div v-show="shifts.length == 0">
      <!-- <p class="text-center">
        
      </p> -->
      <NoDataBox headline="Keine Dienstdaten verfügbar">Für diesen Monat liegen uns keine Informationen vor.</NoDataBox>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

import ModalShiftToExchange from "@/components/ModalShiftToExchange";
import ModalShiftRating from "@/components/ModalShiftRating";
import ModalShiftDiary from "@/components/ModalShiftDiary";
import ShiftBox from "@/components/ShiftBox";
import NoDataBox from "@/components/NoDataBox";

export default {
  name: "Month",
  components: {
    ShiftBox,
    ModalShiftToExchange,
    ModalShiftRating,
    ModalShiftDiary,
    NoDataBox
  },
  data() {
    return {
      isModalVisible: false,
      exchangeData: null,
      hidePastShifts: true,
      isModalRatingVisible: false,
      modalDataRating: null,
      isModalDiaryVisible: false,
      modalDataDiary: null,
    };
  },
  methods: {
    showModal(data) {
      this.exchangeData = data
      this.isModalVisible = true;
      document.body.classList.add("modal-open");
    },
    showModalRating(data) {
      this.modalDataRating = data
      this.isModalRatingVisible = true;
      document.body.classList.add("modal-open");
    },
    showModalDiary(data) {
      this.modalDataDiary = data
      this.isModalDiaryVisible = true;
      document.body.classList.add("modal-open");
    },
    trigger(data) {
      // console.log(data);
    },
    closeModal() {
      this.isModalVisible = false;
      this.isModalRatingVisible = false;
      this.isModalDiaryVisible = false;
      document.body.classList.remove("modal-open");
    },
    calendarLeft() {
      this.$store.dispatch("decrementShiftViewerCalendar").then((a) => {
        // console.log(a);

        // if (a.status == true) {
        //   //this.shifts = a.result;
        // } else {
        //   // this.showAlertWrongData = true
        // }

        // this.isLoading = false;
      });
    },
    calendarRight() {
      // console.log("calendarRight()")
      this.$store.dispatch("incrementShiftViewerCalendar").then((a) => {
        // console.log(a);

        // if (a.status == true) {
        //   //this.shifts = a.result;
        // } else {
        //   // this.showAlertWrongData = true
        // }

        // this.isLoading = false;
      });
    },
    calendarToday() {
      // console.log("calendarToday()")
      this.$store.dispatch("todayShiftViewerCalendar").then((a) => {
        // console.log(a);

        // if (a.status == true) {
        //   //this.shifts = a.result;
        // } else {
        //   // this.showAlertWrongData = true
        // }

        // this.isLoading = false;
      });
    },
  },
  mounted() {
    this.$store.dispatch("fetchShifts").then((a) => {
      // console.log(a);

      if (a.status == true) {
        //this.shifts = a.result;
      } else {
        // this.showAlertWrongData = true
      }

      // this.isLoading = false;
    });
  },
  computed: {
    ...mapGetters({
      shifts: "getShifts",
      shiftViewerCalendar: "getShiftViewerCalendar",
      getMonthLabel: "getMonthLabel"
    }),
  },
};
</script>