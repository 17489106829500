<template>
  <div id="myModal" class="modal">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Diensttausch erstellen</h2>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <div v-if="!isComplete && !isFailed">
            <div class="alertbox border-color-brand bg-card-content">
              <div class="alertbox-icon bg-brand">
                <font-awesome-icon icon="eye" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-maincontent">
                  Dein Tausch erscheint <b>anonym</b> in der Tauschbörse.<br>Erst wenn Du einen
                Tausch annimst kann der/die Tauschpartner/in zusätzlich Deinen
                <b>vollständigen Namen</b> und Deine <b>Personalnummer</b> sehen, um den Tausch bestätigen zu können!
                </p>
              </div>
            </div>
            
            <div class="bg-lines bg-cover bg-center w-full rounded-md p-4 mb-3">
              <div class="content-card-container card-one-center sm:max-w-sm max-w-full mx-auto">
                <ShiftBox :data="shift" :minimalView="true"></ShiftBox>
              </div>
            </div>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-6">
                <div class="form-group">
                  <p><b>Dienstlage</b></p>
                  <div v-if="shift && shift.duty_time">
                    <ServiceSituationIcon :icon="shift.duty_time.typ"></ServiceSituationIcon> {{shift.duty_time.name}}
                  </div>
                  <select v-else @change="stepChecker" class="text-black" style="width: 100%" v-model="exchange.myshift_time">
                    <option value="placeholder">Bitte auswählen...</option>
                    <option v-for="dutyTime in dutyTimes" :key="dutyTime.id" :value="dutyTime.id">{{dutyTime.name}} ({{dutyTime.time_start}} - {{dutyTime.time_end}} Uhr)</option>
                  </select>
                </div>
              </div>
              <div class="col-3"></div>
            </div>

            <div class="content-card-container card-one-center mt-2" v-show="specialRequirements">
              <p><b>Anforderungen</b></p>
              <ul>
                <li v-for="s in specialRequirements" :key="s.id">
                  <label class="checkbox-container">
                    <input type="checkbox" v-model="exchange.myshift_specialRequirements" :value="s.id"/>
                    <span class="checkmark"></span> {{s.name}}
                  </label>
                </li>
              </ul>
            </div>

            <hr class="my-4" />

            <!-- <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <div class="custom-select">
                <select style="width: 100%">
                  <option value="-1">Egal</option>
                  <option value="1">Btf. Merheim</option>
                  <option value="3">Btf. West</option>
                  <option value="4">NEU</option>
                </select>
              </div>
            </div>
            <div class="col-4"></div>
          </div> -->

            <div class="row">
              <div class="col-3"></div>
              <div class="col-6">
                <div class="row form-group grid sm:grid-cols-2 grid-cols-1 gap-2 mb-2">
                  <div class="col-6">
                    <p><b>Dienstbeginn</b> <small>({{exchange.wishshift_shiftstart.length}} gewählt)</small></p>
                    <p class="cursor-pointer" @click="toggleCollapsible_wishshift_shiftstart_depot()"><font-awesome-icon icon="caret-up" class="icon" v-show="collapsible_wishshift_shiftstart_depot"/><font-awesome-icon icon="caret-down" class="icon" v-show="!collapsible_wishshift_shiftstart_depot" @click="toggleCollapsible_wishshift_shiftstart_depot()"/> <b>Betriebshof</b></p>
                    <ul v-show="collapsible_wishshift_shiftstart_depot">
                      <li v-for="station in stations" :key="station.id" v-show="station.depot">
                        <label ><input @change="stepChecker" v-model="exchange.wishshift_shiftstart" type="checkbox" :value="station.id"> {{station.name}} ({{station.shortname}})</label>
                      </li>
                    </ul>
                    <p class="cursor-pointer" @click="toggleCollapsible_wishshift_shiftstart_stations()"><font-awesome-icon icon="caret-up" class="icon" v-show="collapsible_wishshift_shiftstart_stations" /><font-awesome-icon icon="caret-down" class="icon" v-show="!collapsible_wishshift_shiftstart_stations" @click="toggleCollapsible_wishshift_shiftstart_stations()"/> <b>Haltestelle</b></p>
                    <ul v-show="collapsible_wishshift_shiftstart_stations">
                      <li v-for="station in stations" :key="station.id" v-show="!station.depot && station.change_location">
                        <label><input @change="stepChecker" v-model="exchange.wishshift_shiftstart" type="checkbox" :value="station.id"> {{station.name}} ({{station.shortname}})</label>
                      </li>
                    </ul>
                    <small class="text-muted">
                      Wähle mindestens einen bevorzugten Dienstbeginn
                      aus.</small
                    >
                  </div>
                  <div class="col-6">
                    <p><b>Dienstende</b> <small>({{exchange.wishshift_shiftend.length}} gewählt)</small></p>
                    <p class="cursor-pointer" @click="toggleCollapsible_wishshift_shiftend_depot()"><font-awesome-icon icon="caret-up" class="icon" v-show="collapsible_wishshift_shiftend_depot" /><font-awesome-icon icon="caret-down" class="icon" v-show="!collapsible_wishshift_shiftend_depot" @click="toggleCollapsible_wishshift_shiftend_depot()"/> <b>Betriebshof</b></p>
                    <ul v-show="collapsible_wishshift_shiftend_depot">
                      <li v-for="station in stations" :key="station.id" v-show="station.depot">
                        <label ><input @change="stepChecker" v-model="exchange.wishshift_shiftend" type="checkbox" :value="station.id"> {{station.name}} ({{station.shortname}})</label>
                      </li>
                    </ul>
                    <p class="cursor-pointer" @click="toggleCollapsible_wishshift_shiftend_stations()"><font-awesome-icon icon="caret-up" class="icon" v-show="collapsible_wishshift_shiftend_stations"/><font-awesome-icon icon="caret-down" class="icon" v-show="!collapsible_wishshift_shiftend_stations" @click="toggleCollapsible_wishshift_shiftend_stations()"/> <b>Haltestelle</b></p>
                    <ul v-show="collapsible_wishshift_shiftend_stations">
                      <li v-for="station in stations" :key="station.id" v-show="!station.depot && station.change_location">
                        <label><input @change="stepChecker" v-model="exchange.wishshift_shiftend" type="checkbox" :value="station.id"> {{station.name}} ({{station.shortname}})</label>
                      </li>
                    </ul>
                    <small class="text-muted">
                      Wähle mindestens ein bevorzugtes Dienstende
                      aus.</small
                    >
                  </div>
                </div>

                <div class="form-group mb-2">
                  <p><b>Dienstlage</b> <small>({{exchange.wishshift_time.length}} gewählt)</small></p>
                  <!-- <select class="text-black w-full" v-model="exchange.wishshift_time">
                    <option value="-1" selected>-</option>
                    <option v-for="dutyTime in dutyTimes" :key="dutyTime.id" :value="dutyTime.id">{{dutyTime.name}} ({{dutyTime.time_start}} - {{dutyTime.time_end}} Uhr)</option>
                  </select> -->
                  <ul>
                    <li v-for="dutyTime in dutyTimes" :key="dutyTime.id">
                      <label class="checkbox-container">
                        <input @change="stepChecker" type="checkbox" v-model="exchange.wishshift_time" :value="dutyTime.id" />
                        <span class="checkmark"></span> <ServiceSituationIcon :icon="dutyTime.typ"></ServiceSituationIcon> {{dutyTime.name}} ({{dutyTime.time_start}} - {{dutyTime.time_end}} Uhr)
                      </label>
                    </li>
                  </ul>
                  <small class="text-muted"
                    >Wähle mindestens eine Dienstlage aus. Achte
                    auf Deine Ruhezeit.</small
                  >
                </div>

                <div class="form-group mb-2">
                  <p><b>Linie</b> <small>({{exchange.wishshift_lines.length}} gewählt)</small></p>
                  <!-- <ul> -->
                  <div class="flex gap-3 flex-wrap">
                    <div v-for="line in lines" :key="line.id">
                      <label class="checkbox-container">
                        <input @change="stepChecker" type="checkbox" v-model="exchange.wishshift_lines" :value="line.id" />
                        <span class="checkmark"></span> {{line.name}}
                      </label>
                    </div>
                  </div>
                  <!-- </ul> -->
                  <small class="text-muted">Wähle mindestens eine Linie aus.</small>
                </div>

                <div class="form-group mb-2">
                  <p><b>Kommentar</b></p>
                  <textarea
                    id="exampleFormControlTextarea1"
                    rows="3"
                    class="text-black w-full"
                    v-model="exchange.commentar"
                  ></textarea>
                  <small class="text-muted"
                    >Dein Kommentar erscheint bei Deinem Tausch und kann z.B.
                    besondere Voraussetzungen enthalten.</small
                  >
                </div>

                <div class="form-group mb-2" v-show="specialRequirements">
                  <!-- <label class="checkbox-container"
                    >
                    <input type="checkbox" v-model="exchange.wishshift_ebo" />
                    <span class="checkmark"></span> Dienst mit EBO
                  </label> -->
                  <p><b>Anforderungen</b></p>
                  <ul>
                    <li v-for="s in specialRequirements" :key="s.id">
                      <label class="checkbox-container">
                        <input type="checkbox" v-model="exchange.wishshift_specialRequirements" :value="s.id" />
                        <span class="checkmark"></span> {{s.name}}
                      </label>
                    </li>
                  </ul>
                </div>

                <div class="form-group mb-2">
                  <label class="checkbox-container"
                    >
                    <input type="checkbox" v-model="exchange.private" />
                    <span class="checkmark"></span> Privater Tausch
                  </label><br>
                  <small
                    >Der Diensttausch erscheint nicht in der öffentlichen Liste.
                    Du kannst ihn nur mit ausgewählten Kolleginnen & Kollegen
                    teilen.</small
                  >
                </div>

                <!-- <div class="alert alert-default my-3 p-3 rounded-lg text-black bg-gray-300">
                  <p>
                    <font-awesome-icon icon="eye" class="icon" /> Dein Tausch erscheint
                    <b>anonym</b> in der Tauschbörse. Erst wenn Du einen
                    Tausch annimst kann die/der Tauschpartner/in zusätzlich Deinen
                    <b>Vor- & Nachnamen</b> sehen.
                  </p>
                </div> -->

                <button :disabled="stepDisabled" class="button-filled w-full text-center" @click="createExchange()"
                  ><font-awesome-icon
                    icon="paper-plane"
                    class=" text-green-600"
                  />
                  Tausch verbindlich abgeben</button
                >
                <!-- <a
                  href="/daten-transparenz"
                  target="_newTab"
                  class="link text-center"
                  ><font-awesome-icon icon="eye" class="icon" /> Welche Daten
                  werden geteilt?</a
                > -->
              </div>
              <div class="col-3"></div>
            </div>
          </div>
          <div v-else-if="isFailed">
            <div class="notification mx-auto p-3 max-w-md text-center">
              <font-awesome-icon icon="frown" class="icon icon-big text-4xl text-red-600" />
              <p class="headline my-3 font-bold text-red-600">Erstellung fehlgeschlagen</p>
              <hr class="line my-3" />
              <p class="text my-3">
                Bei der Erstellung des Diensttausches ist ein Problem aufgetreten!
              </p>
              <p class="text my-3 text-gray-600 font-sans">
                Bei Support-Anfragen bitte angeben: {{shift.id}}
              </p>
            </div>
          </div>
          <div v-else>

            <!--
            <div class="alertbox border-color-brand">
              <div class="alertbox-icon bg-brand">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-blue-500">
                  <b>2 Dienste verfügbar!</b>
                </p>
                <p class="alertbox-maincontent">
                  Wir haben 2 Dienste gefunden, die Deinen Wünschen entsprechen könnten.
                </p>
                <p class="alertbox-hint">
                  Wähle einen vorgeschlagenen Dienst aus oder stelle Deinen Dienst selber in die Tauschbörse.
                </p>
              </div>
            </div>

            <div class="grid grid-cols-2 lg:grid-cols-2 gap-2">
          
              <div class="content-card w-full shadow-lg" v-for="exchange in exchangesFromUser" :key="exchange.id">
                <div class="card-content rounded-b-lg bg-black px-2 py-2">

                  <ShiftDetails :data="exchange.shift" class="text-center"></ShiftDetails>

                  <div class="mt-3 flex">
                    <a class="card-button w-full text-center">Dienst <b>verbindlich</b> anbieten</a>
                  </div>

                </div>
              </div>
            </div>

            <hr class="my-4">

            <div class="text-center flex flex-col gap-3 items-center">
              <p><b>Unzufrieden mit unseren Empfehlungen?</b><br>Füge Deinen Dienst zur Tauschbörse hinzu, um selber Angebote zu erhalten.</p>
               <button
                @click="close"
                class="button-filled text-center btn-fullsize w-full"
              >
                Zur Tauschbörse hinzufügen
              </button>
            </div>

            <div class="text-center text-gray-600 font-sans text-sm mt-1">
              <p>Uns System kann lernen welche Dienste Dir gefällen. Dein Nutzungserlebnis kann sich verändern. Sag uns gerne Deine Meinung: <i>hallo@stadtbahnfahrer.club</i>.</p>
              <p><small>SHIFT-ID: {{shift.id}}</small></p>
            </div>

            -->
            
            <!-- <div class="text-center flex flex-col gap-3 items-center">
              <font-awesome-icon icon="check" class="icon color-success" />
              <p>Diensttausch erstellt!<br>Dein Dienst wurde zur Tauschbörse hinzugefügt!</p>
               <button
                @click="close"
                class="button-filled text-center btn-fullsize w-full"
              >
                Zur Tauschbörse
              </button>
            </div> -->
           
            <div class="notification mx-auto p-3 max-w-md text-center bg-gray-800 rounded-md shadow-md">
              <font-awesome-icon icon="check" class="icon icon-big" />
              <p class="headline my-3">Diensttausch erstellt</p>
              <hr class="line my-3" />
              <p class="text my-3" v-if="!dispatchResponse.private">
                Dein Dienst wurde zur Tauschbörse hinzugefügt!
              </p>
              <p class="text my-3" v-else>
                Dein privater Diensttausch wurde erstellt. Er ist nicht in der Tauschbörse sichtbar. Nur Kolleginnen & Kollegen, die den Link kennen, können darauf zugreifen.
              </p>
              <p class="text my-3">
                Du kannst den Dienst direkt mit Kollegen:innen teilen:<br><i>https://app.stadtbahnfahrer.club/dienst-tauschen/{{dispatchResponse.id}}</i>
              </p>
              <button class="button-filled text-center btn-fullsize w-full" @click="close">Zur Tauschbörse&nbsp;<font-awesome-icon icon="chevron-right" class="icon" /></button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ShiftBox from "@/components/ShiftBox";
import ServiceSituationIcon from "@/components/ServiceSituationIcon";
import ShiftDetails from "@/components/ShiftDetail";

export default {
  name: "ModalShiftToExchange",
  components: {
    ShiftBox,
    ServiceSituationIcon,
    ShiftDetails
  },
  data() {
    return {
      exchangesFromUser: [
        {
          "id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
          "status": "published",
          "date_created": "2022-01-21T03:21:08.416Z",
          "private": false,
          "commentar": "Ich bin ein kurzer (optionaler) Kommentar, der zusätzlich Informationen zum Tauschangebot vom Nutzer enthalten kann.",
          "shift": {
              "id": "988b0545-1a91-418d-bc43-65495ce13aae",
              "date_created": "2022-01-03T15:20:36.229Z",
              "user_date_unique": "dba9da68-80e4-49fc-b54a-cdda78dfc234_2022-01-24",
              "date": "2022-01-24T00:00:00",
              "header_break": -1,
              "header_nr": "7508",
              "header_service": 1,
              "shift_paidtime": "08:19",
              "shift_workingtime": "08:49",
              "time_start": "16:08",
              "time_end": "00:57+",
              "typ": 1,
              "url": "https://connect.kvb-koeln.de/mbweb/main/ivu/desktop/,DanaInfo=fahrerinfo,Port=20000+duty-details?beginDate=2022-01-24&allocatedEmployeeId=727458",
              "header_date": "2022-01-24T00:00:00",
              "hash": null,
              "date_updated": "2022-01-27T01:24:25.656Z",
              "shift": [
                  {
                      "id": "3abf1aca-71a6-4395-9cb5-530159c52531",
                      "start_location": "NEU",
                      "end_location": "FSP",
                      "sort": 0,
                      "start_time": "16:08",
                      "end_time": "17:53",
                      "start_direction": "SLB",
                      "end_direction": "SLB",
                      "date_created": "2022-01-27T01:24:25.932Z",
                      "date_updated": null,
                      "line": "4",
                      "cycle": "64",
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 0
                  },
                  {
                      "id": "422231db-dd1f-4d50-a42e-4e1e073a3ea3",
                      "start_location": "FSP",
                      "end_location": "NEU",
                      "sort": 1,
                      "start_time": "18:24",
                      "end_time": "20:20",
                      "start_direction": "MEN",
                      "end_direction": "MEN",
                      "date_created": "2022-01-27T01:24:25.938Z",
                      "date_updated": null,
                      "line": "3",
                      "cycle": "63",
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 0
                  },
                  {
                      "id": "daba2003-31fe-4308-a78c-3ed60f089826",
                      "start_location": "S - NEUM",
                      "end_location": "S - NEUM",
                      "sort": 2,
                      "start_time": "20:26",
                      "end_time": "20:56",
                      "start_direction": null,
                      "end_direction": null,
                      "date_created": "2022-01-27T01:24:25.948Z",
                      "date_updated": null,
                      "line": null,
                      "cycle": null,
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 1
                  },
                  {
                      "id": "52b51702-5cf1-4136-8dc1-d275ffd58992",
                      "start_location": "NEU",
                      "end_location": "MRH-S",
                      "sort": 3,
                      "start_time": "21:00",
                      "end_time": "00:51",
                      "start_direction": "VRS",
                      "end_direction": "",
                      "date_created": "2022-01-27T01:24:25.960Z",
                      "date_updated": null,
                      "line": "3",
                      "cycle": "61",
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 0
                  }
              ],
              "exchange": [
                  "9912b572-6bfd-4dcc-918a-e2c12e875ad2"
              ],
              "ml_shifts": [],
              "user": {
                  "id": "dba9da68-80e4-49fc-b54a-cdda78dfc234",
                  "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                  "verified": true,
                  "blocked": false,
                  "confirmed": true,
                  "servicecard_id": "123456789",
                  "last_access": "2022-02-01T23:13:33.562Z",
                  "status": "active",
                  "confirmed_at": "2021-10-12T01:32:36.353Z",
                  "verified_at": "2022-01-01T22:05:45.662Z",
                  "first_name": "Tobias",
                  "last_name": "Test Scheler",
                  "last_page": "/content/shifts/988b0545-1a91-418d-bc43-65495ce13aae",
                  "email": "tobias.scheler@me.com",
                  "password": "**********",
                  "location": null,
                  "title": null,
                  "description": null,
                  "tags": null,
                  "avatar": null,
                  "language": "en-US",
                  "theme": "auto",
                  "tfa_secret": null,
                  "role": "ac869b88-3e03-4edf-8539-16ff67f44355",
                  "verification": [
                      "dba9da68-80e4-49fc-b54a-cdda78dfc234"
                  ]
              }
          },
          "myshift_time": {
              "id": "bc07fa2a-8625-4e0f-9f6b-372d3639d2ad",
              "time_start": "15:00:40",
              "time_end": "19:59:51",
              "name": "Spätdienst",
              "typ": 3
          },
          "wishshift_specialrequirements": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "special_requirements_id": {
                      "id": "8d59edbd-0aa7-4cd1-a362-7ae700787a45",
                      "sort": null,
                      "name": "Dienst mit EBO"
                  }
              }
          ],
          "wishshift_lines": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "a4b0a2eb-5417-405e-bc86-efc3acb61b17",
                      "name": "1",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "5209167",
                      "facilities": [
                          1
                      ],
                      "speedlimits": [
                          17,
                          23
                      ]
                  }
              },
              {
                  "id": 2,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "5f51a8b2-2830-4249-9ab0-1d6cc73cea34",
                      "name": "12",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "36133",
                      "facilities": [],
                      "speedlimits": [
                          6,
                          12
                      ]
                  }
              },
              {
                  "id": 3,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "f44b463c-ce0d-4c9b-aba5-2e54024c0aca",
                      "name": "15",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "36134",
                      "facilities": [],
                      "speedlimits": [
                          7,
                          11,
                          13,
                          25
                      ]
                  }
              },
              {
                  "id": 4,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "d779a6f7-93fd-421c-9b10-fae432541aff",
                      "name": "18",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "1646141",
                      "facilities": [
                          2,
                          4,
                          7
                      ],
                      "speedlimits": [
                          3,
                          5,
                          22,
                          31
                      ]
                  }
              },
              {
                  "id": 5,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "c02103be-3f88-424c-a859-e0e2c23a1389",
                      "name": "3",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "2026288",
                      "facilities": [
                          3,
                          5
                      ],
                      "speedlimits": [
                          19,
                          29
                      ]
                  }
              },
              {
                  "id": 6,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "dc81d5b4-890b-48ef-be49-07d770b518c8",
                      "name": "4",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "5742445",
                      "facilities": [],
                      "speedlimits": [
                          18,
                          27
                      ]
                  }
              }
          ],
          "wishshift_shiftstart": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "stations_id": {
                      "id": "358b8e66-8844-4f0b-83f2-79e1cf11e2b3",
                      "user_created": "12d0e7d2-b53e-42b6-ae03-c3d961db4d80",
                      "date_created": "2021-08-10T17:59:27.778Z",
                      "user_updated": "fe8b4b98-416a-4491-bc58-abe634918286",
                      "date_updated": "2022-01-18T23:55:24.619Z",
                      "name": "Bf Deutz/LANXESS-Arena",
                      "shortname": "BDK",
                      "depot": false,
                      "change_location": true,
                      "osm_relation": null,
                      "osm_relations": null,
                      "osm_nodes": [
                          38,
                          39
                      ]
                  }
              }
          ],
          "wishshift_shiftend": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "stations_id": {
                      "id": "358b8e66-8844-4f0b-83f2-79e1cf11e2b3",
                      "user_created": "12d0e7d2-b53e-42b6-ae03-c3d961db4d80",
                      "date_created": "2021-08-10T17:59:27.778Z",
                      "user_updated": "fe8b4b98-416a-4491-bc58-abe634918286",
                      "date_updated": "2022-01-18T23:55:24.619Z",
                      "name": "Bf Deutz/LANXESS-Arena",
                      "shortname": "BDK",
                      "depot": false,
                      "change_location": true,
                      "osm_relation": null,
                      "osm_relations": null,
                      "osm_nodes": [
                          38,
                          39
                      ]
                  }
              },
              {
                  "id": 2,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "stations_id": {
                      "id": "1e9f5341-697f-4bb4-a8ee-8db254cfe737",
                      "user_created": "12d0e7d2-b53e-42b6-ae03-c3d961db4d80",
                      "date_created": "2021-08-10T17:58:13.890Z",
                      "user_updated": "fe8b4b98-416a-4491-bc58-abe634918286",
                      "date_updated": "2022-01-10T00:15:19.193Z",
                      "name": "Friesenplatz",
                      "shortname": "FSP",
                      "depot": false,
                      "change_location": true,
                      "osm_relation": null,
                      "osm_relations": null,
                      "osm_nodes": []
                  }
              }
          ],
          "wishshift_time": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "duty_times_id": {
                      "id": "bc07fa2a-8625-4e0f-9f6b-372d3639d2ad",
                      "time_start": "15:00:40",
                      "time_end": "19:59:51",
                      "name": "Spätdienst",
                      "typ": 3
                  }
              }
          ],
          "myshift_specialrequirements": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "special_requirements_id": {
                      "id": "8d59edbd-0aa7-4cd1-a362-7ae700787a45",
                      "sort": null,
                      "name": "Dienst mit EBO"
                  }
              }
          ]
      },
      {
          "id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
          "status": "published",
          "date_created": "2022-01-21T03:21:08.416Z",
          "private": false,
          "commentar": "Ich bin ein kurzer (optionaler) Kommentar, der zusätzlich Informationen zum Tauschangebot vom Nutzer enthalten kann.",
          "shift": {
              "id": "988b0545-1a91-418d-bc43-65495ce13aae",
              "date_created": "2022-01-03T15:20:36.229Z",
              "user_date_unique": "dba9da68-80e4-49fc-b54a-cdda78dfc234_2022-01-24",
              "date": "2022-01-24T00:00:00",
              "header_break": -1,
              "header_nr": "7508",
              "header_service": 1,
              "shift_paidtime": "08:19",
              "shift_workingtime": "08:49",
              "time_start": "16:08",
              "time_end": "00:57+",
              "typ": 1,
              "url": "https://connect.kvb-koeln.de/mbweb/main/ivu/desktop/,DanaInfo=fahrerinfo,Port=20000+duty-details?beginDate=2022-01-24&allocatedEmployeeId=727458",
              "header_date": "2022-01-24T00:00:00",
              "hash": null,
              "date_updated": "2022-01-27T01:24:25.656Z",
              "shift": [
                  {
                      "id": "3abf1aca-71a6-4395-9cb5-530159c52531",
                      "start_location": "NEU",
                      "end_location": "FSP",
                      "sort": 0,
                      "start_time": "16:08",
                      "end_time": "17:53",
                      "start_direction": "SLB",
                      "end_direction": "SLB",
                      "date_created": "2022-01-27T01:24:25.932Z",
                      "date_updated": null,
                      "line": "4",
                      "cycle": "64",
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 0
                  },
                  {
                      "id": "422231db-dd1f-4d50-a42e-4e1e073a3ea3",
                      "start_location": "FSP",
                      "end_location": "NEU",
                      "sort": 1,
                      "start_time": "18:24",
                      "end_time": "20:20",
                      "start_direction": "MEN",
                      "end_direction": "MEN",
                      "date_created": "2022-01-27T01:24:25.938Z",
                      "date_updated": null,
                      "line": "3",
                      "cycle": "63",
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 0
                  },
                  {
                      "id": "daba2003-31fe-4308-a78c-3ed60f089826",
                      "start_location": "S - NEUM",
                      "end_location": "S - NEUM",
                      "sort": 2,
                      "start_time": "20:26",
                      "end_time": "20:56",
                      "start_direction": null,
                      "end_direction": null,
                      "date_created": "2022-01-27T01:24:25.948Z",
                      "date_updated": null,
                      "line": null,
                      "cycle": null,
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 1
                  },
                  {
                      "id": "52b51702-5cf1-4136-8dc1-d275ffd58992",
                      "start_location": "NEU",
                      "end_location": "MRH-S",
                      "sort": 3,
                      "start_time": "21:00",
                      "end_time": "00:51",
                      "start_direction": "VRS",
                      "end_direction": "",
                      "date_created": "2022-01-27T01:24:25.960Z",
                      "date_updated": null,
                      "line": "3",
                      "cycle": "61",
                      "shift": "988b0545-1a91-418d-bc43-65495ce13aae",
                      "typ": 0
                  }
              ],
              "exchange": [
                  "9912b572-6bfd-4dcc-918a-e2c12e875ad2"
              ],
              "ml_shifts": [],
              "user": {
                  "id": "dba9da68-80e4-49fc-b54a-cdda78dfc234",
                  "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                  "verified": true,
                  "blocked": false,
                  "confirmed": true,
                  "servicecard_id": "123456789",
                  "last_access": "2022-02-01T23:13:33.562Z",
                  "status": "active",
                  "confirmed_at": "2021-10-12T01:32:36.353Z",
                  "verified_at": "2022-01-01T22:05:45.662Z",
                  "first_name": "Tobias",
                  "last_name": "Test Scheler",
                  "last_page": "/content/shifts/988b0545-1a91-418d-bc43-65495ce13aae",
                  "email": "tobias.scheler@me.com",
                  "password": "**********",
                  "location": null,
                  "title": null,
                  "description": null,
                  "tags": null,
                  "avatar": null,
                  "language": "en-US",
                  "theme": "auto",
                  "tfa_secret": null,
                  "role": "ac869b88-3e03-4edf-8539-16ff67f44355",
                  "verification": [
                      "dba9da68-80e4-49fc-b54a-cdda78dfc234"
                  ]
              }
          },
          "myshift_time": {
              "id": "bc07fa2a-8625-4e0f-9f6b-372d3639d2ad",
              "time_start": "15:00:40",
              "time_end": "19:59:51",
              "name": "Spätdienst",
              "typ": 3
          },
          "wishshift_specialrequirements": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "special_requirements_id": {
                      "id": "8d59edbd-0aa7-4cd1-a362-7ae700787a45",
                      "sort": null,
                      "name": "Dienst mit EBO"
                  }
              }
          ],
          "wishshift_lines": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "a4b0a2eb-5417-405e-bc86-efc3acb61b17",
                      "name": "1",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "5209167",
                      "facilities": [
                          1
                      ],
                      "speedlimits": [
                          17,
                          23
                      ]
                  }
              },
              {
                  "id": 2,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "5f51a8b2-2830-4249-9ab0-1d6cc73cea34",
                      "name": "12",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "36133",
                      "facilities": [],
                      "speedlimits": [
                          6,
                          12
                      ]
                  }
              },
              {
                  "id": 3,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "f44b463c-ce0d-4c9b-aba5-2e54024c0aca",
                      "name": "15",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "36134",
                      "facilities": [],
                      "speedlimits": [
                          7,
                          11,
                          13,
                          25
                      ]
                  }
              },
              {
                  "id": 4,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "d779a6f7-93fd-421c-9b10-fae432541aff",
                      "name": "18",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "1646141",
                      "facilities": [
                          2,
                          4,
                          7
                      ],
                      "speedlimits": [
                          3,
                          5,
                          22,
                          31
                      ]
                  }
              },
              {
                  "id": 5,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "c02103be-3f88-424c-a859-e0e2c23a1389",
                      "name": "3",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "2026288",
                      "facilities": [
                          3,
                          5
                      ],
                      "speedlimits": [
                          19,
                          29
                      ]
                  }
              },
              {
                  "id": 6,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "lines_id": {
                      "id": "dc81d5b4-890b-48ef-be49-07d770b518c8",
                      "name": "4",
                      "company": "2e547b8b-8cef-4c55-888c-198c3e75069e",
                      "osm_relation": "5742445",
                      "facilities": [],
                      "speedlimits": [
                          18,
                          27
                      ]
                  }
              }
          ],
          "wishshift_shiftstart": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "stations_id": {
                      "id": "358b8e66-8844-4f0b-83f2-79e1cf11e2b3",
                      "user_created": "12d0e7d2-b53e-42b6-ae03-c3d961db4d80",
                      "date_created": "2021-08-10T17:59:27.778Z",
                      "user_updated": "fe8b4b98-416a-4491-bc58-abe634918286",
                      "date_updated": "2022-01-18T23:55:24.619Z",
                      "name": "Bf Deutz/LANXESS-Arena",
                      "shortname": "BDK",
                      "depot": false,
                      "change_location": true,
                      "osm_relation": null,
                      "osm_relations": null,
                      "osm_nodes": [
                          38,
                          39
                      ]
                  }
              }
          ],
          "wishshift_shiftend": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "stations_id": {
                      "id": "358b8e66-8844-4f0b-83f2-79e1cf11e2b3",
                      "user_created": "12d0e7d2-b53e-42b6-ae03-c3d961db4d80",
                      "date_created": "2021-08-10T17:59:27.778Z",
                      "user_updated": "fe8b4b98-416a-4491-bc58-abe634918286",
                      "date_updated": "2022-01-18T23:55:24.619Z",
                      "name": "Bf Deutz/LANXESS-Arena",
                      "shortname": "BDK",
                      "depot": false,
                      "change_location": true,
                      "osm_relation": null,
                      "osm_relations": null,
                      "osm_nodes": [
                          38,
                          39
                      ]
                  }
              },
              {
                  "id": 2,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "stations_id": {
                      "id": "1e9f5341-697f-4bb4-a8ee-8db254cfe737",
                      "user_created": "12d0e7d2-b53e-42b6-ae03-c3d961db4d80",
                      "date_created": "2021-08-10T17:58:13.890Z",
                      "user_updated": "fe8b4b98-416a-4491-bc58-abe634918286",
                      "date_updated": "2022-01-10T00:15:19.193Z",
                      "name": "Friesenplatz",
                      "shortname": "FSP",
                      "depot": false,
                      "change_location": true,
                      "osm_relation": null,
                      "osm_relations": null,
                      "osm_nodes": []
                  }
              }
          ],
          "wishshift_time": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "duty_times_id": {
                      "id": "bc07fa2a-8625-4e0f-9f6b-372d3639d2ad",
                      "time_start": "15:00:40",
                      "time_end": "19:59:51",
                      "name": "Spätdienst",
                      "typ": 3
                  }
              }
          ],
          "myshift_specialrequirements": [
              {
                  "id": 1,
                  "exchanges_id": "9912b572-6bfd-4dcc-918a-e2c12e875ad2",
                  "special_requirements_id": {
                      "id": "8d59edbd-0aa7-4cd1-a362-7ae700787a45",
                      "sort": null,
                      "name": "Dienst mit EBO"
                  }
              }
          ]
      }
      ],
      show: this.showModal,
      exchange: {
        myshift_specialRequirements: [],
        myshift_time: "placeholder",
        private: false,
        commentar: null,
        wishshift_specialRequirements: [],
        wishshift_shiftstart: [],
        wishshift_shiftend: [],
        wishshift_time: [],
        wishshift_lines: [],
      },
      isComplete: false,
      isFailed: false,
      dispatchResponse: null,
      lines: [],
      stations: [],
      dutyTimes: [],
      specialRequirements: [],
      collapsible_wishshift_shiftstart_stations: false,
      collapsible_wishshift_shiftstart_depot: false,
      collapsible_wishshift_shiftend_stations: false,
      collapsible_wishshift_shiftend_depot: false,
      stepDisabled: true
    };
  },
  props: {
    image: String,
    showModal: false,
    shift: null,
  },
  methods: {
    stepChecker() {
      if(typeof this.exchange !== "undefined") {
        if( typeof this.exchange.myshift_time !== "undefined" && 
            typeof this.exchange.wishshift_shiftstart !== "undefined" && 
            typeof this.exchange.wishshift_shiftend !== "undefined" && 
            typeof this.exchange.wishshift_time !== "undefined" && 
            typeof this.exchange.wishshift_lines !== "undefined") {
          if(
          (this.exchange.myshift_time != "placeholder" || this.shift.duty_time) && 
          this.exchange.wishshift_shiftstart.length > 0 && 
          this.exchange.wishshift_shiftend.length > 0 && 
          this.exchange.wishshift_time.length > 0 && 
          this.exchange.wishshift_lines.length > 0
          ) {
            this.stepDisabled = false
            console.log("ok");
            return
          } else {
          console.log("#1");
          }
        } else {
          console.log("#2");
        }
      } else {
        console.log("#3");
      }

      console.log("no");
      this.stepDisabled = true
    },
    close() {
      this.$emit("close");
      this.isComplete = false
      this.isFailed = false
      this.dispatchResponse = null
      this.exchange = {
        myshift_specialRequirements: [],
        myshift_time: "placeholder",
        private: false,
        commentar: null,
        wishshift_specialRequirements: [],
        wishshift_shiftstart: [],
        wishshift_shiftend: [],
        wishshift_time: [],
        wishshift_lines: [],
      }
    },
    toggleCollapsible_wishshift_shiftstart_stations() {
      if(this.collapsible_wishshift_shiftstart_stations) {
        this.collapsible_wishshift_shiftstart_stations = false
      } else {
        this.collapsible_wishshift_shiftstart_stations = true
      }
    },
    toggleCollapsible_wishshift_shiftstart_depot() {
      if(this.collapsible_wishshift_shiftstart_depot) {
        this.collapsible_wishshift_shiftstart_depot = false
      } else {
        this.collapsible_wishshift_shiftstart_depot = true
      }
    },
    toggleCollapsible_wishshift_shiftend_stations() {
      if(this.collapsible_wishshift_shiftend_stations) {
        this.collapsible_wishshift_shiftend_stations = false
      } else {
        this.collapsible_wishshift_shiftend_stations = true
      }
    },
    toggleCollapsible_wishshift_shiftend_depot() {
      if(this.collapsible_wishshift_shiftend_depot) {
        this.collapsible_wishshift_shiftend_depot = false
      } else {
        this.collapsible_wishshift_shiftend_depot = true
      }
    },
    createExchange() {
      this.exchange.shift = this.shift;

      if(this.exchange.myshift_time == "placeholder") {
        this.exchange.myshift_time = this.shift.duty_time.id
      }

      this.$store.dispatch("createExchange", this.exchange).then((a) => {
        if (a.status == true) {
          this.dispatchResponse = a.response
          this.isComplete = true
        } else {
          this.isFailed = true
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch("fetchLines").then((a) => {
      if (a.status == true) {
        this.lines = a.response
      }
    });

    this.$store.dispatch("fetchStations",{
      "_or": [
        {
          "depot": {
            "_eq": true
          }
        },
        {
          "change_location": {
            "_eq": true
          }
        }
      ]
    }).then((a) => {
      if (a.status == true) {
        this.stations = a.response
      }
    });

    this.$store.dispatch("fetchDutyTimes").then((a) => {
      if (a.status == true) {
        this.dutyTimes = a.response
      }
    });

    this.$store.dispatch("fetchSpecialRequirements").then((a) => {
      if (a.status == true) {
        this.specialRequirements = a.response
      }
    });
  },
  computed: {
    ...mapGetters({
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate",
      getServiceSituationIcon: "getServiceSituationIcon"
    })
  }
};
</script>




