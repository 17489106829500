<template>
  <div id="ModalShiftRating" class="modal">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Dienst bewerten</h2>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="modal-body" v-if="shift">
          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 2">
            <font-awesome-icon icon="check" class="icon color-success" />
            <p>Bewertung gespeichert!<br>Vielen Dank für Deine Unterstützung.</p>
          </div>

          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 3">
            <!-- <font-awesome-icon icon="check" class="icon color-success" /> -->
            <p>Fehler<br>Es leider ein Fehler aufgetreten. Versuche es bitte erneut.</p>
          </div>

          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 1">
            <font-awesome-icon icon="spinner" class="icon" pulse />
            <p></p>
          </div>

          <div class="text-center flex flex-col gap-3" v-show="status == 0">
            <p class="text-lg">Bewerte Deinen Dienst am<br><b>{{ getWeekdayForDate(shift.date, false) }}, den {{ getFormatedDate(shift.date) }}</b></p>
            
            <div>
              <p><b>Bewertung begründen</b> <small>(optional)</small></p>
              <textarea
                id="exampleFormControlTextarea1"
                rows="3"
                class="text-black w-full"
                v-model="commentar"
              ></textarea>
              <small class="text-muted">Optionale Begründung Deiner Bewertung. Kann zur Verbesserung des Algorithmuses beitragen.</small>
            </div>
            
            <div class="flex flex-row gap-2">
              <button
                @click="sendActionPositiv()"
                class="button-filled text-center btn-fullsize w-full"
              >
                <span class="color-success">Positiv</span><br>bewerten
              </button>
              <button
                @click="sendActionNegativ()"
                class="button-filled text-center btn-fullsize w-full"
              >
                <span class="color-danger">Negativ</span><br>bewerten
              </button>
            </div>
            
          </div>

          <div class="text-center text-gray-600 font-sans text-sm mt-1">
            <p>Unser System kann lernen welche Dienste Dir gefallen. Dein Nutzungserlebnis kann sich verändern. Die Auswahl wird <b>nicht</b> mit Deinem Arbeitgeber geteilt. Sag uns gerne Deine Meinung: <i>hallo@stadtbahnfahrer.club</i>.</p>
            <p><small>SHIFT-ID: {{shift.id}}</small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalShiftRating",
  data() {
    return {
      show: this.showModal,
      status: 0,
      commentar: null
    };
  },
  props: {
    showModal: false,
    shift: null
  },
  mounted() {
    // this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate"
    }),
  },
  methods: {
    sendActionPositiv() {
      this.sendAction(1);
    },
    sendActionNegativ() {
      this.sendAction(0);
    },
    sendAction(a) {
      this.status = 1

      this.$store.dispatch("setRatingForShift", {
        commentar:this.commentar,
        rating:a,
        shift:this.shift
        }).then((a) => {
        if (a.status == true) {
          this.status = 2
        } else {
          this.status = 3
        }
      });
    },
    close() {
      // this.status = 0
      this.$emit("close");
    },
  },
};
</script>




